import {
    MDBCard,
    MDBCardImage,
    MDBCol,
    MDBContainer,
    MDBRow,
} from "mdb-react-ui-kit";
import Image from "next/image";
import { FC, useEffect, useState } from "react";
import { ISectionTitle } from "../../../models/common/common";
import { StepsInfo } from "../../../models/home";
import fourStepsPic from "../../../public/images/illustration-newsletter.png";
import styles from "../../../styles/home/Home.FourSteps.module.scss";
import {
    fourStepsContents,
    fourStepsImage,
    fourStepsSubTitle,
    fourStepsTitle,
} from "../../../utils/mockData/homeMocks";
import { randomUid } from "../../../utils/randomIds";
import SectionTitle from "../../common/SectionTitle";
import { useIsSM } from "../../../utils/hooks/useWindowSizes";
import FourStepsCarousel from "./FourStepsCarousel";

const FourSteps: FC<{
    light?: boolean;
    stepperImage?: string;
}> = ({ light, stepperImage }) => {
    const [itemList, setItemList] = useState<
        { value: StepsInfo; id?: string }[]
    >([]);

    useEffect(() => {
        setItemList(
            fourStepsContents.map((item: StepsInfo) => ({
                value: item,
                id: randomUid(),
            }))
        );
    }, []);

    const titleOne: ISectionTitle = {
        title: fourStepsTitle,
        subTitle: fourStepsSubTitle,
        classNameContainer: "ms-auto ms-md-0 mx-0 px-0 py-3",
        alignment:
            "justify-content-md-start text-md-start justify-content-md-center text-md-center",
    };

    return (
        <MDBContainer
            fluid
            className={`${
                light ? styles.backgroundWhiteTwo : styles.backgroundWhite
            } py-5 px-md-0`}
        >
            <MDBRow className="px-5">
                <SectionTitle sectionTitle={titleOne} />
            </MDBRow>
            <MDBRow className="mx-md-5 px-5 px-md-0 px-lg-5 mt-md-5">
                <MDBCol size={10} md={5}>
                    <MDBRow>
                        {itemList.map((el, id) => (
                            <MDBCol
                                key={el.id}
                                size={12}
                                className="ms-md-5 pe-md-5 me-md-5 "
                            >
                                <span key={el.id}>
                                    <div className={styles.numberStep}>{`0${
                                        id + 1
                                    }`}</div>
                                    <SectionTitle
                                        sectionTitle={{
                                            title: el.value.title,
                                            subTitle: el.value.subTitle,
                                            classNameTitle: styles.textTitle,
                                            classNameSubTitle:
                                                styles.textSubTitle,
                                            alignment:
                                                "justify-content-start text-start",
                                            titleColumnSize: 12,
                                            classNameContainer: "py-3",
                                        }}
                                    />
                                </span>
                            </MDBCol>
                        ))}
                    </MDBRow>
                </MDBCol>
                {!useIsSM() && (
                    <MDBCol center md={5} className="me-lg-5 pe-lg-5 flex-fill">
                        <Image
                            src={stepperImage ?? fourStepsPic}
                            alt="Picture of the brochure"
                            width={600}
                            height={700}
                            sizes="320 640 750"
                        />
                    </MDBCol>
                )}
            </MDBRow>
            {useIsSM() && (
                <MDBRow>
                    <FourStepsCarousel>
                        {fourStepsImage.map(el => (
                            <MDBCard className={styles.carouselCard}>
                                <MDBCardImage
                                    className={styles.cardImage}
                                    overlay
                                    src={el}
                                />
                            </MDBCard>
                        ))}
                    </FourStepsCarousel>
                </MDBRow>
            )}
        </MDBContainer>
    );
};

FourSteps.defaultProps = {
    light: false,
};

export default FourSteps;
